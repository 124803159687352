import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section className="AboutUs__section">
      <div className="AboutUs--Left">
        <h2 className="AboutUs__title">About Us</h2>
        <p className="AboutUs__text">
          Through 20+ years of experience, we understood that the marriage
          between brokerage and maintenance is a “happily ever after” for real
          estate investment.
        </p>
        <p className="AboutUs__text">
          We help our clients achieve their goals through reliable asset
          management and reporting. We achieve our goals with honesty, fairness
          and long-term partnerships.
        </p>
        <p className="AboutUs__text">
          Maintenance services are exclusive to properties we list or manage.
        </p>
        <div className="AboutUs__buttons">
          <button className="AboutUs__btn AboutUs__ourStory">Our Story</button>
          <button className="AboutUs__btn AboutUs__joinUs">Join Us</button>
        </div>
      </div>
      <div className="AboutUs--Right">
        <form action="#" className="AboutUs__Form" method="post">
          <p className="AboutUs__FormTitle">Let's Grow Together</p>
          <div className="AboutUs__Form--Inputs">
            <input type="text" placeholder="John Doe" id="AboutUsName" />
            <input
              type="email"
              placeholder="johndoe@mail.com"
              id="AboutUsEmail"
            />
            <input type="tel" placeholder="+1" id="AboutUsPhone" />
            <input type="submit" value="Submit" className="AboutUs__Submit" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default AboutUs;
