import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import BluLogo from "../../../public/assets/img/icon/Logos/Blu.svg";
import UserIcon from "../../../public/assets/img/icon/user.svg";
import SearchIcon from "../../../public/assets/img/icon/Search.svg";
import SearchBarIcon from "../../../public/assets/img/icon/searchBarIcon.svg";
import BurgerIcon from "./assets/BurgerIcon.svg";

const Header = () => {
  // Estado para manejar el menú burger
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  useEffect(() => {
    const searchLi = document.getElementById("searchLi");
    const searchButton = document.getElementById("searchButton");
    const searchBar = document.getElementById("searchBar");
    const searchInput = searchBar.querySelector("input");
    const searchBarIcon = document.getElementById("searchBarIcon");
    const Header = document.getElementById("Top");
    const HeaderLogo = document.getElementById("HeaderLogo");

    function showSearchBar() {
      searchLi.style.display = "none";
      searchBar.style.display = "block";
      searchBar.classList.add("slideDown");
      searchBar.classList.remove("slideUp");
      Header.style.height = "210px";
      searchBar.style.width = "60%";
      HeaderLogo.style.width = "180px";
      searchInput.placeholder = "MLS";
      searchBar.style.border = "";
      searchBar.style.boxShadow = "";
      searchBarIcon.src = SearchBarIcon;
    }

    function hideSearchBar() {
      searchBar.classList.add("slideUp");
      searchBar.classList.remove("slideDown");
      searchBar.style.width = "0px";
      searchBar.style.backgroundColor = "#2699fb";
      searchBar.style.borderRadius = "50%";
      searchBar.style.border = "none";
      searchBar.style.boxShadow = "none";
      searchInput.placeholder = "";
      searchBarIcon.src = SearchIcon;

      setTimeout(() => {
        searchBar.style.backgroundColor = "";
        searchBar.style.borderRadius = "";
        searchBar.style.display = "none";
        searchLi.style.display = "block";
        Header.style.height = "150px";
        HeaderLogo.style.width = "120px";
      }, 300);
    }

    searchButton.addEventListener("click", (event) => {
      event.preventDefault();
      showSearchBar();
    });

    searchLi.addEventListener("click", (event) => {
      event.preventDefault();
      showSearchBar();
    });

    searchBarIcon.addEventListener("click", (event) => {
      event.preventDefault();
      hideSearchBar();
    });

    return () => {
      searchButton.removeEventListener("click", showSearchBar);
      searchLi.removeEventListener("click", showSearchBar);
      searchBarIcon.removeEventListener("click", hideSearchBar);
    };
  }, []);

  // Función para alternar el estado del menú burger
  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  return (
    <header className="header glassEffect" id="Top">
      <Link to="/" className="header__logo--link">
        <div
          className="header__logo"
          id="HeaderLogo"
          style={{ width: "120px" }}
        >
          <img
            draggable="false"
            src={BluLogo}
            alt="Blu Realty & Investments Logo"
            className="header__logo-img"
          />
        </div>
      </Link>
      <div className="burgerMenu">
        <button className="burgerMenu" onClick={toggleBurgerMenu}>
          <img src={BurgerIcon} alt="" aria-hidden />
          <p>Blu Realty & Investments</p>
        </button>
      </div>
      <nav className="header__nav">
        <ul
          className={`header__nav-ul ${
            burgerMenuOpen ? "burgerOpened" : "burgerClosed"
          }`}
        >
          <div
            className="closeBurgerMenu"
            onClick={() => setBurgerMenuOpen(false)}
          ></div>
          <img
            draggable="false"
            src={BluLogo}
            alt="Blu Realty & Investments Logo"
            className="header__burger-img"
          />
          <li className="header__nav-li">
            <Link
              to="/book-a-call"
              className="header__link hvr-underline-from-center"
            >
              Book a Call
            </Link>
          </li>
          <li className="header__nav-li">
            <Link
              to="/buying"
              className="header__link hvr-underline-from-center"
            >
              Buying
            </Link>
          </li>
          <li className="header__nav-li">
            <Link
              to="/selling"
              className="header__link hvr-underline-from-center"
            >
              Selling
            </Link>
          </li>
          <li className="header__nav-li">
            <Link
              to="/management"
              className="header__link hvr-underline-from-center"
            >
              Management
            </Link>
          </li>
          <li className="header__nav-li">
            <Link
              to="/market-report"
              className="header__link hvr-underline-from-center"
            >
              Market Report
            </Link>
          </li>
          <li
            className="header__nav-li hvr-underline-from-center userLi"
            style={{ height: "3.22rem" }}
          >
            <Link to="/login" className="header__link header__link--LogIn">
              <img
                draggable="false"
                src={UserIcon}
                alt="Log In"
                className="userIcon"
              />
              Log In
            </Link>
          </li>
          <li className="header__nav-li" id="searchLi">
            <button
              id="searchButton"
              className="header__link hvr-underline-from-center"
              style={{
                paddingBottom: "1rem",
                marginTop: "1rem",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <div className="header__link--Search">
                <img
                  draggable="false"
                  src={SearchIcon}
                  alt="Search"
                  className="header__link--SearchImg"
                />
              </div>
            </button>
          </li>
          <span className="copyrightBurgerMenu">
            Copyright © 2024 <br /> Blu Realty & Investments Company. <br /> All
            rights reserved.
          </span>
        </ul>
        <div id="searchBar" className="header__searchBar">
          <input type="text" placeholder="MLS" />
          <img
            draggable="false"
            src={SearchBarIcon}
            alt="Search MLS"
            id="searchBarIcon"
            className="header__searchBarIcon"
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
