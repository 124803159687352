import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "../public/assets/styles/Global.css";
import "../public/assets/styles/Hover.css";
import Header from "./components/Header/Header.jsx";
import Quotes from "./components/Quotes/Quotes.jsx";
import Footer from "./components/Footer/Footer.jsx";
import HeroSection from "./components/HeroSection/HeroSection.jsx";
import AboutUs from "./components/AboutUs/AboutUs.jsx";
import ServicesSection from "./components/ServicesSection/ServicesSection.jsx";
import CoreServices from "./components/CoreServices/CoreServices.jsx";
import StarContact from "./components/StarContact/StarContact.jsx";
import HeroBioPage from "./components/HeroBioPage/HeroBioPage.jsx";
import BioSection from "./components/BioPageAgent/BioSection.jsx";
import BuyerBrokerAgreement from "./components/BuyerBrokerAgreement/BuyerBrokerAgreement.jsx";
import BioServicesSection from "./components/BioServicesSection/BioServicesSection.jsx";
import AgentContact from "./components/AgentContact/AgentContact.jsx";
import BluContentSection from "./components/BluContentSection/BluContentSection.jsx";
import MAForm from "./components/MAForm/MAForm.jsx";

const Home = () => (
  <main className="main">
    <HeroSection />
    <ServicesSection />
    <CoreServices />
    <StarContact />
    <AboutUs />
    <Quotes />
  </main>
);

const Buying = () => <div>Buying Page</div>;
const Selling = () => <div>Selling Page</div>;
const Management = () => <div>Management Page</div>;
const MarketReport = () => <div>Market Report Page</div>;
const LogIn = () => <div>Log In Page</div>;
const BookACall = () => <div>Book a Call Page</div>;
const Concierge = () => <div>Concierge Page</div>;
const Storm = () => <div>Storm Page</div>;
const BluWorld = () => <div>BluWorld Page</div>;
const MarineLifestyle = () => <div>Miami Marine Lifestyle Page</div>;
const PrivacyPolicy = () => <div>Privacy Policy Page</div>;
const TermsConditions = () => <div>Terms & Conditions Page</div>;

const JP = () => (
  <>
    <HeroBioPage />
    <BioSection />
    <BuyerBrokerAgreement />
    <BioServicesSection />
    <AgentContact />
    <BluContentSection />
    <Quotes />
  </>
);

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/ma-form" element={<MAForm />} />

          {/* Ruta para la página principal (Home) */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          />

          {/* Ruta para la página de JP */}
          <Route
            path="/jp"
            element={
              <>
                <Header />
                <JP />
                <Footer />
              </>
            }
          />

          {/* Rutas adicionales */}
          <Route path="/buying" element={<Buying />} />
          <Route path="/selling" element={<Selling />} />
          <Route path="/management" element={<Management />} />
          <Route path="/market-report" element={<MarketReport />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/book-a-call" element={<BookACall />} />
          <Route path="/concierge" element={<Concierge />} />
          <Route path="/storm" element={<Storm />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/bluworld" element={<BluWorld />} />
          <Route path="/marine-lifestyle" element={<MarineLifestyle />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />

          {/* Redirección predeterminada si se accede a una ruta no válida */}
          <Route path="*" element={<Navigate to="/app/" />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
