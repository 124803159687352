const AboutMeSection = ({ isActive }) => (
  <div
    className={`bio-content__aboutme ${isActive ? "active" : "hidden"}`}
    style={{
      opacity: isActive ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    }}
  >
    <h2 id="bioContentTitle" className="bio-content-title">
      About Me
    </h2>
    <div className="bio-content__aboutme__container">
      <h3 className="bio-agent-name">Jean</h3>
      <h3 className="bio-agent-last-name">Parra</h3>
      <h3 className="bio-agent-subtitle bio-agent-subtitle--AboutMe">
        Licensed Real Estate Broker.
      </h3>
      <p className="bio-agent-description">
        Over two decades of passion for technology and real estate have led to
        the creation of the world's <strong>first AI-powered</strong> real
        estate company. With unwavering commitment to humility, gratitude, and
        meticulous attention to detail, I'm dedicated to helping clients and our
        team achieve their goals.
      </p>
    </div>
  </div>
);

export default AboutMeSection;
