import React, { useEffect } from "react";
import "./BioServicesSection.css";
import SellingSmall from "../../../public/assets/img/SellingSmall.svg";
import AcquisitionsSmall from "../../../public/assets/img/AcquisitionsSmall.svg";
import VirtualStaging from "../../../public/assets/img/virtualStaging.jpg";
import Staging from "../../../public/assets/img/Staging.jpg";
import Timeline1 from "../../../public/assets/img/Timeline1.svg";
import Timeline2 from "../../../public/assets/img/Timeline2.svg";
import Timeline3 from "../../../public/assets/img/Timeline3.svg";
import Timeline4 from "../../../public/assets/img/Timeline4.svg";
import ExampleDashboard from "../../../public/assets/img/example.svg";

const BioServicesSection = () => {
  useEffect(() => {
    // Slider logic
    let isSliderDown = false;
    let sliderStartX;
    let sliderScrollLeft;
    const slider = document.querySelector(".items");

    const sliderEnd = () => {
      isSliderDown = false;
      slider.classList.remove("active");
    };

    const sliderStart = (e) => {
      isSliderDown = true;
      slider.classList.add("active");
      sliderStartX = (e.pageX || e.touches[0].pageX) - slider.offsetLeft;
      sliderScrollLeft = slider.scrollLeft;
    };

    const sliderMove = (e) => {
      if (!isSliderDown) return;
      e.preventDefault();
      const x = (e.pageX || e.touches[0].pageX) - slider.offsetLeft;
      const dist = x - sliderStartX;
      slider.scrollLeft = sliderScrollLeft - dist;
    };

    slider.addEventListener("mousedown", sliderStart);
    slider.addEventListener("touchstart", sliderStart);
    slider.addEventListener("mousemove", sliderMove);
    slider.addEventListener("touchmove", sliderMove);
    slider.addEventListener("mouseleave", sliderEnd);
    slider.addEventListener("mouseup", sliderEnd);
    slider.addEventListener("touchend", sliderEnd);

    // Carousel logic
    let currentImg = 1;

    function slide(id) {
      currentImg = Number(id);
      document.querySelector("li.active-img").classList.remove("active-img");
      document
        .querySelector(`ul#carousel-imgs > li:nth-child(${currentImg})`)
        .classList.add("active-img");

      document.querySelector("li.active-dot").classList.remove("active-dot");
      document
        .querySelector(`ul#carousel-dots li:nth-child(${currentImg})`)
        .classList.add("active-dot");
    }

    function previousSlide() {
      if (currentImg === 1) {
        slide(2);
      } else {
        slide(currentImg - 1);
      }
    }

    function nextSlide() {
      if (currentImg === 2) {
        slide(1);
      } else {
        slide(currentImg + 1);
      }
    }

    document.querySelectorAll("ul#carousel-dots li").forEach((e) => {
      e.addEventListener("click", () => {
        slide(e.dataset.triggerslide);
      });
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        previousSlide();
      } else if (e.key === "ArrowRight") {
        nextSlide();
      }
    });

    document.querySelectorAll("ul#carousel-imgs li").forEach((e) => {
      e.addEventListener("click", nextSlide);
    });

    // Functionality to toggle 'Selling' and 'Acquisitions' sections
    const btnAcquisitions = document.getElementById("btnAcquisitions");
    const btnSelling = document.getElementById("btnSelling");
    const acquisitionsSection = document.querySelector(
      ".easyBuyingAcquisitions"
    );
    const sellingSection = document.querySelector(".easyBuyingSelling");

    const handleAcquisitionsClick = () => {
      const acquisitionsHeight = "994px";

      if (!acquisitionsSection.classList.contains("hidden")) {
        acquisitionsSection.style.height =
          acquisitionsSection.scrollHeight + "px";
        acquisitionsSection.style.opacity = "1";
        requestAnimationFrame(() => {
          acquisitionsSection.style.transition =
            "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
          acquisitionsSection.style.height = "0px";
          acquisitionsSection.style.opacity = "0";
        });
        acquisitionsSection.addEventListener(
          "transitionend",
          () => {
            acquisitionsSection.classList.add("hidden");
            acquisitionsSection.style.height = "";
            acquisitionsSection.style.opacity = "";
          },
          { once: true }
        );
      } else {
        if (!sellingSection.classList.contains("hidden")) {
          sellingSection.style.height = sellingSection.scrollHeight + "px";
          sellingSection.style.opacity = "1";
          requestAnimationFrame(() => {
            sellingSection.style.transition =
              "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
            sellingSection.style.height = "0px";
            sellingSection.style.opacity = "0";
          });
          sellingSection.addEventListener(
            "transitionend",
            () => {
              sellingSection.classList.add("hidden");
              sellingSection.style.height = "";
              sellingSection.style.opacity = "";
            },
            { once: true }
          );
        }

        acquisitionsSection.classList.remove("hidden");
        acquisitionsSection.style.height = "0px";
        acquisitionsSection.style.opacity = "0";
        acquisitionsSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        requestAnimationFrame(() => {
          acquisitionsSection.style.height = acquisitionsHeight;
          acquisitionsSection.style.opacity = "1";
        });
        acquisitionsSection.addEventListener(
          "transitionend",
          () => {
            acquisitionsSection.style.height = "auto";
          },
          { once: true }
        );
      }
    };

    const handleSellingClick = () => {
      const sellingHeight = "800px";

      if (!sellingSection.classList.contains("hidden")) {
        sellingSection.style.height = sellingSection.scrollHeight + "px";
        sellingSection.style.opacity = "1";
        requestAnimationFrame(() => {
          sellingSection.style.transition =
            "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
          sellingSection.style.height = "0px";
          sellingSection.style.opacity = "0";
        });
        sellingSection.addEventListener(
          "transitionend",
          () => {
            sellingSection.classList.add("hidden");
            sellingSection.style.height = "";
            sellingSection.style.opacity = "";
          },
          { once: true }
        );
      } else {
        if (!acquisitionsSection.classList.contains("hidden")) {
          acquisitionsSection.style.height =
            acquisitionsSection.scrollHeight + "px";
          acquisitionsSection.style.opacity = "1";
          requestAnimationFrame(() => {
            acquisitionsSection.style.transition =
              "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
            acquisitionsSection.style.height = "0px";
            acquisitionsSection.style.opacity = "0";
          });
          acquisitionsSection.addEventListener(
            "transitionend",
            () => {
              acquisitionsSection.classList.add("hidden");
              acquisitionsSection.style.height = "";
              acquisitionsSection.style.opacity = "";
            },
            { once: true }
          );
        }

        sellingSection.classList.remove("hidden");
        sellingSection.style.height = "0px";
        sellingSection.style.opacity = "0";
        sellingSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        requestAnimationFrame(() => {
          sellingSection.style.height = sellingHeight;
          sellingSection.style.opacity = "1";
        });
        sellingSection.addEventListener(
          "transitionend",
          () => {
            sellingSection.style.height = "auto";
          },
          { once: true }
        );
      }
    };

    btnAcquisitions.addEventListener("click", handleAcquisitionsClick);
    btnSelling.addEventListener("click", handleSellingClick);

    // Clean up event listeners on unmount
    return () => {
      slider.removeEventListener("mousedown", sliderStart);
      slider.removeEventListener("touchstart", sliderStart);
      slider.removeEventListener("mousemove", sliderMove);
      slider.removeEventListener("touchmove", sliderMove);
      slider.removeEventListener("mouseleave", sliderEnd);
      slider.removeEventListener("mouseup", sliderEnd);
      slider.removeEventListener("touchend", sliderEnd);
      btnAcquisitions.removeEventListener("click", handleAcquisitionsClick);
      btnSelling.removeEventListener("click", handleSellingClick);
    };
  }, []);

  return (
    <section className="services__section">
      <div className="services__section__ContainerItems">
        <div
          className="services__selling easyBuying__sellacq--item"
          id="btnSelling"
        >
          <div className="services__selling--Overlay"></div>
          <img src={SellingSmall} alt="Selling" draggable="false" />
          <h3>Selling</h3>
        </div>
        <div
          className="services__acquisitions easyBuying__sellacq--item"
          id="btnAcquisitions"
        >
          <div className="services__acquisitions--Overlay"></div>
          <img src={AcquisitionsSmall} alt="Acquisitions" draggable="false" />
          <h3>Acquisitions</h3>
        </div>
      </div>

      <div className="easyBuyingSelling hidden">
        <div id="carousel-root">
          <ul id="carousel-imgs">
            <li className="active-img">
              <img
                src={VirtualStaging}
                alt="Virtual Staging"
                draggable="false"
              />
            </li>
            <li>
              <img src={Staging} alt="Staging" draggable="false" />
            </li>
          </ul>
          <ul id="carousel-dots">
            <li data-triggerslide="1"></li>
            <li className="active-dot" data-triggerslide="2"></li>
          </ul>
        </div>
        <h2 className="easyBuyingSelling__title">Marketing Strategy</h2>
        <div className="wrapperDragTimeline">
          <ul className="items">
            <li className="item">
              <img src={Timeline1} alt="Timeline 1" draggable="false" />
            </li>
            <li className="item">
              <img src={Timeline2} alt="Timeline 2" draggable="false" />
            </li>
            <li className="item">
              <img src={Timeline3} alt="Timeline 3" draggable="false" />
            </li>
            <li className="item">
              <img src={Timeline4} alt="Timeline 4" draggable="false" />
            </li>
          </ul>
        </div>
      </div>

      <div className="easyBuyingAcquisitions hidden">
        <h2 className="easyBuying__subtitle">Easy Buying</h2>
        <h2 className="easyBuying__title">Effortless Efficiency.</h2>
        <p className="easyBuying__text">
          All in one report. Easy buying. Effortless efficiency. With our Buyer
          Presentation Report, the{" "}
          <strong>buying experience is simple. Grow your portfolio</strong>{" "}
          without management worries. Our advanced technology delivers detailed
          reports that keep you informed and in control, streamlining the
          process and maximizing your results.
        </p>
        <img
          src={ExampleDashboard}
          alt="Example Dashboard Image"
          draggable="false"
          className="easyBuying__img"
        />
      </div>
    </section>
  );
};

export default BioServicesSection;
