import React, { useState } from "react";
import "./AgentContact.css";

const AgentContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    // Aquí puedes agregar la lógica para enviar los datos del formulario
  };

  return (
    <section className="agentContact">
      <div className="agentContactContent">
        <div className="agentContactLeft">
          <h2 className="agentContactLets">Let's Start</h2>
          <h2 className="agentContactConversation">The Conversation!</h2>
          <p className="agentContactSubtitle">Please verify we got it right!</p>
        </div>
        <div className="agentContactRight">
          <form
            action=""
            className="agentContact__Form"
            onSubmit={handleSubmit}
          >
            <div className="agentContact__Form--InputContainer">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                type="tel"
                name="phone"
                placeholder="WhatsApp Phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="agentContactButtonContainer">
        <input
          type="submit"
          value="Submit"
          className="agentContact__Button"
          onClick={handleSubmit}
        />
      </div>
    </section>
  );
};

export default AgentContact;
