import React from "react";
import BluWhiteLogo from "../../../public/assets/img/icon/Logos/BluWhite.svg";
import DownArrow from "../../../public/assets/img/icon/bioPageDownArrow.svg";
import "./HeroBioPage.css";

const HeroBioPage = () => {
  return (
    <div className="overlay-container">
      <section className="bioPageHero__section hero__overlayVideo">
        <div className="bioPageHero__logo">
          <img
            src={BluWhiteLogo}
            alt="Blu Realty & Investments"
            draggable="false"
          />
        </div>
        <h1 className="bioPageHero__title">
          Life is Sweet when Stress is Minimal!
        </h1>
        <div className="bioPageHero__downArrow">
          <img draggable="false" src={DownArrow} alt="Down Arrow" />
        </div>
      </section>
    </div>
  );
};

export default HeroBioPage;
