import { React, useState } from "react";

const PriceAndTermsSection = ({ onDataChange }) => {
  const [part2Data, setPart2Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart2Data({
      ...part2Data,
      [name]: value,
    });
    onDataChange({
      ...part2Data,
      [name]: value,
    });
  };
  
  return (
    <section className="priceAndTerms__Section">
      <h2 className="section__Title lineHeight">
        <span>3. Price and Terms:</span> The property is offered for sale and/or
        lease on the following terms or on other terms acceptable to Seller:
      </h2>

      <label
        htmlFor="priceAndTermsPrice"
        className="sectionTwelve__DropdownLabel"
      >
        (a) Price:
      </label>
      <input
        type="text"
        placeholder="$000.000.000"
        className="tenantInfo__Input priceAndTerms__Inputs"
        name="priceAndTermsPrice"
        id="priceAndTermsPrice"
        onChange={handleChange}
      />

      <label
        htmlFor="priceAndTermsLease"
        className="sectionTwelve__DropdownLabel"
      >
        Lease:
      </label>
      <input
        type="text"
        placeholder="$000.000.000"
        className="tenantInfo__Input priceAndTerms__Inputs"
        name="priceAndTermsLease"
        id="priceAndTermsLease"
        onChange={handleChange}
      />

      <div className="propertyOccupancy">
        <h2 className="propertyType__Title financingTerms__Title">
          (b) Financing Terms:
        </h2>

        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="FinancingTerms_Cash">
            Cash
            <input
              type="checkbox"
              value="Yes"
              id="FinancingTerms_Cash"
              name="FinancingTerms_Cash"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="FinancingTerms_Convetional"
          >
            Conventional
            <input
              type="checkbox"
              value="Yes"
              id="FinancingTerms_Convetional"
              name="FinancingTerms_Convetional"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="FinancingTerms_VAFHA">
            VA/FHA
            <input
              type="checkbox"
              value="Yes"
              id="FinancingTerms_VAFHA"
              name="FinancingTerms_VAFHA"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      <h3 className="copyLatestLeaseAgreements ownerExpenses lineHeight">
        (c) <span>Owner</span> Expenses: <span>Owner</span> will pay mortgage
        discount or other closing costs not to exceed 3% of the purchase price
        and any other expenses <span>Owner</span> agrees to pay in connection
        with a transaction.
      </h3>
    </section>
  );
};

export default PriceAndTermsSection;
