import { React, useState } from "react";
import "./MAForm.css";
import SectionTwelve from "./SectionTwelve";
import PriceAndTermsSection from "./PriceAndTermsSection";
import ContactInformation from "./ContactInformation";
import MailingAddress from "./MailingAddress";
import BankDeposit from "./BankDeposit";
import InsuranceSection from "./InsuranceSection";
import InsurancePropertySection from "./InsurancePropertySection";
import InsuranceRentersSection from "./InsuranceRentersSection";
import InsuranceMortgageSection from "./InsuranceMortgageSection";
import HomeownersAssociationSection from "./HomeownersAssociationSection";
import UtilitiesSection from "./UtilitiesSection";
import FinalButtonSection from "./FinalButtonSection"; // Importamos la nueva sección

import axios from "axios";

const MASection = () => {
  /* each section of the form sends a part of data "part<numSection>Data"
  in this state save the information of all  forms parts  */
  const [formData, setFormData] = useState({});

  /*every change in form is uploaded to the state  */
  const handleChange = (data) => {
    setFormData({
      ...formData,
      ...data,
    });
  };

  /* information collected in the state is sent to the catalyst endpoint to be uploaded to the DataStore*/
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        //server endPoint
        "/server/blu_realty_function/post",
        formData
      );
      //TODO: check whether to send alert when completing the form
      console.log(`Data sent correctly: ${JSON.stringify(response.data)}`);
    } catch (error) {
      console.error(`Error sending data: ${error}`);
    }
  };

  return (
    <form>
      <SectionTwelve onDataChange={(data) => handleChange(data)} />
      <PriceAndTermsSection onDataChange={(data) => handleChange(data)} />
      <ContactInformation onDataChange={(data) => handleChange(data)} />
      <MailingAddress onDataChange={(data) => handleChange(data)} />
      <BankDeposit onDataChange={(data) => handleChange(data)} />
      <InsuranceSection onDataChange={(data) => handleChange(data)} />
      <InsurancePropertySection onDataChange={(data) => handleChange(data)} />
      <InsuranceRentersSection onDataChange={(data) => handleChange(data)} />
      <InsuranceMortgageSection onDataChange={(data) => handleChange(data)} />
      <HomeownersAssociationSection
        onDataChange={(data) => handleChange(data)}
      />
      <UtilitiesSection onDataChange={(data) => handleChange(data)} />
      <FinalButtonSection onSubmit={handleSubmit} />{" "}
      {/* Agregamos el botón de envío aquí */}
    </form>
  );
};

export default MASection;
