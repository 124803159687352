import React, { useEffect, useState } from "react";
import "./Quotes.css";

const quotes = [
  { quote: "Today is the day. If not today when?", author: "Eckhart Tolle" },
  {
    quote:
      "There is no one to be, there is nowhere to be other than who and where you are right now!",
    author: "Blu Team",
  },
  { quote: "Life is Now", author: "Eckhart Tolle" },
  {
    quote:
      "Only a company like ours can provide you true Real Estate Tranquility",
    author: "Blu Team",
  },
  {
    quote: "What happen to the 11yr old child inside of you? Dream again!",
    author: "Blu Difference",
  },
  { quote: "Quality homes for quality people", author: "Blu Difference" },
  { quote: "We are amazing Human Beings!", author: "Blu Difference" },
  {
    quote: "Paying high Taxes? Not even on April Fools!",
    author: "Liane Hikind",
  },
  {
    quote: "We are the bridge between Real Estate investment & your goals",
    author: "Blu Difference",
  },
  {
    quote: "Happiness is in between what we have and what we want",
    author: "Earl Nightingale",
  },
  {
    quote: "You don’t attract what you want, you attract what you are",
    author: "Wayne Dyer",
  },
  {
    quote:
      "Having a solid property management team is essential to grow your portfolio",
    author: "Blu Team",
  },
  {
    quote: "Productive Reporting Available Online 24 hours",
    author: "Blu Difference",
  },
  { quote: "A stitch in time saves nine", author: "Unknown" },
  {
    quote: "If you can see it in your mind, you can hold it in your hand",
    author: "Bob Proctor",
  },
  { quote: "Life is sweeter when stress is minimal", author: "Liane Hikind" },
  {
    quote: "We all have 24 hours. The key is how to utilize every second",
    author: "Blu Team",
  },
  {
    quote: "The harder the struggle the more glorious the triumph",
    author: "Thomas Paine",
  },
  {
    quote: "You no longer have to hesitate to make that decision",
    author: "Blu Difference",
  },
  {
    quote: "He works so hard, he doesn’t have time to make money",
    author: "Carlos Arturo Jara",
  },
  {
    quote: "If you change what you say, you will change what you see",
    author: "Joel Osteen",
  },
  {
    quote: "Grow your portfolio without the management worries",
    author: "Blu Difference",
  },
  {
    quote: "The best way to predict your future is to create it",
    author: "Abraham Lincoln",
  },
  {
    quote: "You can achieve your goals, by helping others achieve theirs",
    author: "Zig Ziglar",
  },
  {
    quote: "Always working towards earning your business and trust!",
    author: "Blu Team",
  },
];

const RandomQuote = () => {
  const [quoteText, setQuoteText] = useState("");
  const [authorText, setAuthorText] = useState("");
  const [isLongQuote, setIsLongQuote] = useState(false);

  useEffect(() => {
    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      return quotes[randomIndex];
    };

    const displayQuote = () => {
      const randomQuote = getRandomQuote();
      const quote = randomQuote.quote;
      const author = randomQuote.author;

      setQuoteText(quote);
      setAuthorText(author);
      setIsLongQuote(quote.length > 70);
    };

    displayQuote();
  }, []);

  return (
    <div className="scroll-left">
      <div className="scroll-container" id="scroll-container">
        <div className="main__quote">
          <p className="quoteExample" id="quote1">
            {quoteText}
          </p>
          {isLongQuote && (
            <p
              className="quoteExample"
              id="quote2"
              style={{ margin: "0 8rem" }}
            >
              {quoteText}
            </p>
          )}
        </div>
      </div>
      <p className="nameExample" id="author">
        — {authorText}
      </p>
    </div>
  );
};

export default RandomQuote;
