import { React, useState } from "react";

const BankDeposit = ({ onDataChange }) => {
  const [part5Data, setPart5Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart5Data({
      ...part5Data,
      [name]: value,
    });
    onDataChange({
      ...part5Data,
      [name]: value,
    });
  };

  return (
    <section className="bankDeposit__Section">
      <h3 className="section__Title weightBold">
        Please provide your bank account information for rent deposit
      </h3>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="bankDeposit__OwnerName"
          className="sectionTwelve__Label weightBold"
        >
          Bank Account Owner Name:
        </label>
        <input
          type="text"
          name="bankDeposit__OwnerName"
          className="sectionTwelve__Input"
          id="bankDeposit__OwnerName"
          onChange={handleChange}
        />
      </div>

      <div className="bankDeposit__InputGrid">
        <input
          type="text"
          placeholder="Bank"
          name="bankDeposit_BankInput"
          id="bankDeposit_BankInput"
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="XXXX-0000"
          name="bankDeposit_CheckInput"
          id="bankDeposit_CheckInput"
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Account type"
          name="bankDeposit_AccTypeInput"
          id="bankDeposit_AccTypeInput"
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Type your instructions here."
          name="bankDeposit_InstructionsInput"
          id="bankDeposit_InstructionsInput"
          onChange={handleChange}
        />
      </div>

      <h3 className="section__Title weightBold marginTopTwo">
        Owner authorizes Manager to make payments on Owner’s behalf: (check all
        that apply)
      </h3>

      <div className="dFlex spaceBet">
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="bankDeposit_authorizesLegalFees"
          >
            Legal Fees
            <input
              type="checkbox"
              name="ownerAuthorizesPayments"
              id="bankDeposit_authorizesLegalFees"
              value="Legal Fees"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="bankDeposit_authorizesPropertyTaxes"
          >
            Property Taxes
            <input
              type="checkbox"
              name="ownerAuthorizesPayments"
              id="bankDeposit_authorizesPropertyTaxes"
              value="Property Taxes"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="bankDeposit_authorizesLicensingFees"
          >
            Rental Licensing Fees
            <input
              type="checkbox"
              name="ownerAuthorizesPayments"
              id="bankDeposit_authorizesLicensingFees"
              value="Rental Licensing Fees"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    </section>
  );
};

export default BankDeposit;
