import React, { useState } from "react";
import "./ServicesSection.css";
import SunnyIsles2 from "../../../public/assets/img/SunnyIsles2.jpeg";
import ApartBedroom from "../../../public/assets/img/ApartBedroom.jpeg";
import SellingIcon from "../../../public/assets/img/icon/Selling.svg";
import AcquisitionsIcon from "../../../public/assets/img/icon/Acquisitions.svg";
import DownArrow from "../../../public/assets/img/icon/downArrow.svg";
import CloseIcon from "../../../public/assets/img/icon/closeIcon.svg";
import AcquisitionsIcon1 from "../../../public/assets/img/icon/AcquisitionsIcon1.svg";
import AcquisitionsIcon2 from "../../../public/assets/img/icon/AcquisitionsIcon2.svg";
import AcquisitionsIcon3 from "../../../public/assets/img/icon/AcquisitionsIcon3.svg";
import AcquisitionsIcon4 from "../../../public/assets/img/icon/AcquisitionsIcon4.svg";
import AcquisitionsIcon5 from "../../../public/assets/img/icon/AcquisitionsIcon5.svg";
import AcquisitionsIcon6 from "../../../public/assets/img/icon/AcquisitionsIcon6.svg";

const SellingSection = ({ togglePopup }) => (
  <div
    className="main__service main__serviceSelling"
    id="openSelling"
    style={{ backgroundImage: `url(${SunnyIsles2})` }}
    onClick={() => togglePopup("selling")}
  >
    <div className="main__serviceSelling--Overlay"></div>
    <div className="mainService__icon">
      <img src={SellingIcon} alt="Selling Services" draggable="false" />
    </div>
    <h3 className="mainService__title">Selling</h3>
    <p className="mainService__text">
      As the world's first <strong>AI-powered</strong> real estate company, we
      have created a platform that frees our sales team to build relationships,
      while our <strong>expert in-house maintenance technicians</strong> resolve
      property needs.
    </p>
    <p className="quoteSellingAcquisitions">Smooth transaction!</p>
  </div>
);

const AcquisitionsSection = ({ togglePopup }) => (
  <div
    className="main__service main__serviceAcquisitions"
    id="openAcquisitions"
    style={{ backgroundImage: `url(${ApartBedroom})` }}
    onClick={() => togglePopup("acquisitions")}
  >
    <div className="main__serviceAcquisitions--Overlay"></div>
    <div className="mainService__icon">
      <img
        src={AcquisitionsIcon}
        alt="Acquisitions Services"
        draggable="false"
      />
    </div>
    <h3 className="mainService__title">Acquisitions</h3>
    <p className="mainService__text">
      Specializing in Miami, the Blu buying experience is simple. <br />
      Grow your portfolio without management worries.
    </p>
    <p className="quoteSellingAcquisitions">
      Life is sweeter when stress is minimal!
    </p>
  </div>
);

const ServicesSection = () => {
  // Estado para controlar la visibilidad de los popups
  const [popupVisible, setPopupVisible] = useState({
    selling: false,
    acquisitions: false,
  });

  // Función para abrir/cerrar popups
  const togglePopup = (popupType) => {
    setPopupVisible((prevState) => ({
      ...prevState,
      [popupType]: !prevState[popupType],
    }));
  };

  return (
    <section className="main__services">
      <SellingSection togglePopup={togglePopup} />
      <AcquisitionsSection togglePopup={togglePopup} />

      {/* Selling PopUp */}
      {popupVisible.selling && (
        <div className="sellingServicesPopUp popup">
          <div className="sellingServicesContent">
            <div className="sellingServices__top">
              <h3 className="sellingServicesPopUp__title">Selling Services</h3>
              <p className="sellingServicesPopUp__text">
                Skilled negotiators. Proven marketing strategies: exposure in
                100+ real estate websites, social media & direct mail generate a
                frenzy of active buyers and deliver <strong>Top Dollar</strong>{" "}
                results.
              </p>
            </div>
            <div className="closePopUpContainer">
              <div className="closePopUp" style={{ cursor: "default" }}>
                <img
                  className="downArrow"
                  src={DownArrow}
                  alt="Down Arrow of Selling Services PopUp"
                />
              </div>
            </div>
            <form className="sellingServicesForm" method="post">
              <div className="sellingServices__topInputs">
                <input
                  type="text"
                  name="sellingServicesName"
                  placeholder="Name"
                />
                <input
                  type="email"
                  name="sellingServicesEmail"
                  placeholder="E-mail"
                />
                <input
                  type="tel"
                  name="sellingServicesPhone"
                  placeholder="Phone"
                />
              </div>
              <input
                type="text"
                name="sellingServicesAddress"
                placeholder="Complete Address, Zip Code"
                style={{ width: "100%" }}
              />
              <p className="sellingServicesText">
                Are there any additions or remodels that add value?
              </p>
              <input
                type="submit"
                value="Submit"
                className="sellingServicesSubmit"
              />
            </form>
            <div
              className="closePopUpContainer"
              onClick={() => togglePopup("selling")}
            >
              <div className="closePopUp">
                <img src={CloseIcon} alt="Close Selling Services PopUp" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Acquisitions PopUp */}
      {popupVisible.acquisitions && (
        <div className="AcquisitionsPopUp popup">
          <div className="AcquisitionsContent">
            <div className="Acquisitions__top">
              <h3 className="AcquisitionsPopUp__title">Our Clients Enjoy</h3>
            </div>
            <div className="AcquisitionsCircleIcons">
              <div className="AcquisitionsCircleIconsColumnOne">
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon1}
                      alt="Market and ROI Analysis"
                      className="AcquisitionsIconImg1"
                      draggable="false"
                    />
                  </div>
                  <p>Market and ROI Analysis</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon2}
                      alt="Geographical, Demographical & Censual Reports"
                      className="AcquisitionsIconImg2"
                      draggable="false"
                    />
                  </div>
                  <p>Geographical, Demographical & Censual Reports</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon3}
                      alt="Foreclosure Acquisitions"
                      className="AcquisitionsIconImg3"
                      draggable="false"
                    />
                  </div>
                  <p>Foreclosure Acquisitions</p>
                </div>
              </div>
              <div className="AcquisitionsCircleIconsColumnTwo">
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon4}
                      alt="Asset Preservation"
                      className="AcquisitionsIconImg4"
                      draggable="false"
                    />
                  </div>
                  <p>Asset Preservation</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon5}
                      alt="Clear Budgets & Insightful Reports"
                      className="AcquisitionsIconImg5"
                      draggable="false"
                    />
                  </div>
                  <p>Clear Budgets & Insightful Reports</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon6}
                      alt="Resident Retention"
                      className="AcquisitionsIconImg6"
                      draggable="false"
                    />
                  </div>
                  <p>Resident Retention</p>
                </div>
              </div>
            </div>
            <div className="AcquisitionsImg">
              <div className="Acquisitions__info">
                <h3>Local Insurers & Tax Attorneys</h3>
                <ul>
                  <li>Liability</li>
                  <li>Ordinance Compliance & Lien Reduction</li>
                  <li>Domestic and Foreign Businesses Formations</li>
                  <li>Trusts & Estate Planning</li>
                </ul>
              </div>
            </div>
          </div>
          <form className="AcquisitionsForm" method="post">
            <p className="AcquisitionsPopUp__text">
              By focusing on the detailed needs of real estate investment, we
              cultivate long-term & mutually successful relationships.
            </p>
            <div className="Acquisitions__topInputs">
              <input
                type="text"
                name="AcquisitionsName"
                id="AcquisitionsName"
                placeholder="Name"
              />
              <input
                type="email"
                name="AcquisitionsEmail"
                id="AcquisitionsEmail"
                placeholder="E-mail"
              />
              <input
                type="tel"
                name="AcquisitionsPhone"
                id="AcquisitionsPhone"
                placeholder="Phone"
              />
            </div>
            <input
              type="text"
              name="AcquisitionsAddress"
              id="AcquisitionsAddress"
              placeholder="Complete Address, Zip Code"
              style={{ width: "100%" }}
            />
            <p className="AcquisitionsText">
              Specific interest or requirements
            </p>
            <input
              type="submit"
              value="Submit"
              className="AcquisitionsSubmit"
            />
          </form>
          <div
            className="closePopUpContainer"
            onClick={() => togglePopup("acquisitions")}
          >
            <div className="closePopUp">
              <img src={CloseIcon} alt="Close Acquisitions Services PopUp" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServicesSection;
