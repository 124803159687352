import {React,  useState } from "react";

const ContactInformation = ({ onDataChange }) => {
  const [showSecondEmail, setShowSecondEmail] = useState(false);
  const [showSecondTelephone, setShowSecondTelephone] = useState(false);

  const [part3Data, setPart3Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart3Data({
      ...part3Data,
      [name]: value,
    });
    onDataChange({
      ...part3Data,
      [name]: value,
    });
  };

  return (
    <section className="contactInformation__Section">
      <h2 className="section__Title">
        <span>12. Contact Information</span>
      </h2>

      <input
        type="text"
        placeholder="Name of person we are to update and contact when needed"
        className="contactInformation__Input contactInformation__Input--Name"
        name="contactInformation_Name"
        id="contactInformation_Name"
        onChange={handleChange}
      />

      <div className="dFlex contactInformation__Inputs">
        <div className="dFColumn">
          <label
            htmlFor="contactInformation_Email"
            className="sectionTwelve__Label mTwoRem"
          >
            Email:
          </label>
          <input
            type="email"
            className="contactInformation__Input"
            name="contactInformation_Email"
            id="contactInformation_Email"
            onChange={handleChange}
          />
          <span
            id="contact_addEmail"
            className="addInfo"
            onClick={() => setShowSecondEmail(!showSecondEmail)}
          >
            + Second Email
          </span>
          {showSecondEmail && (
            <input
              type="email"
              className="contactInformation__Input"
              name="contactInformation_SecondEmail"
              id="contactInformation_SecondEmail"
              onChange={handleChange}
            />
          )}
        </div>

        <div className="dFColumn">
          <label
            htmlFor="contactInformation_Mobile"
            className="sectionTwelve__Label mTwoRem"
          >
            Mobile:
          </label>
          <input
            type="tel"
            className="contactInformation__Input"
            name="contactInformation_Mobile"
            id="contactInformation_Mobile"
            onChange={handleChange}
          />
          <span
            id="contact_addTelephone"
            className="addInfo"
            onClick={() => setShowSecondTelephone(!showSecondTelephone)}
          >
            + Second Telephone
          </span>
          {showSecondTelephone && (
            <input
              type="tel"
              className="contactInformation__Input"
              name="contactInformation_SecondMobile"
              id="contactInformation_SecondMobile"
              onChange={handleChange}
            />
          )}
        </div>
      </div>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="contactInformation_Address"
          className="sectionTwelve__Label weightBold"
        >
          Address:
        </label>
        <input
          type="text"
          name="contactInformation_Address"
          className="sectionTwelve__Input"
          id="contactInformation_Address"
          onChange={handleChange}
        />
      </div>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="contactInformation_City"
          className="sectionTwelve__Label"
        >
          City:
        </label>
        <input
          type="text"
          name="contactInformation_City"
          className="sectionTwelve__Input"
          id="contactInformation_City"
          onChange={handleChange}
        />
        <label
          htmlFor="contactInformation_State"
          className="sectionTwelve__Label marginLeft"
        >
          State:
        </label>
        <input
          type="text"
          name="contactInformation_State"
          className="sectionTwelve__Input"
          id="contactInformation_State"
          onChange={handleChange}
        />
        <label
          htmlFor="contactInformation_ZipCode"
          className="sectionTwelve__Label marginLeft"
        >
          ZipCode:
        </label>
        <input
          type="text"
          name="contactInformation_ZipCode"
          className="sectionTwelve__Input"
          id="contactInformation_ZipCode"
          onChange={handleChange}
        />
      </div>
    </section>
  );
};

export default ContactInformation;
