import React from "react";
import "./HeroSection.css";
import bgVideo from "../../../public/assets/video/bgVideo.mp4";
import bgPoster from "../../../public/assets/img/bgPoster.png";

const HeroSection = () => {
  return (
    <section className="main__hero">
      <div className="hero__overlayVideo"></div>
      <video
        muted
        autoPlay
        loop
        playsInline
        poster={bgPoster}
        alt="Photo of Sunny Isles"
        className="video"
      >
        <source src={bgVideo} type="video/mp4" alt="Video of Sunny Isles" />
      </video>
      <form
        action="submit_form.php"
        className="formMlsSearcher hero__content hero__mlsSearcher"
        method="post"
      >
        <input
          type="text"
          className="input__mlsSearcher"
          placeholder="Sales, Lease, Commercial"
        />
        <input type="submit" value="Search" className="bttn_mlsSearcher" />
      </form>
      <div className="hero__signature preventSelect">
        <p>Blu Realty & Investments</p>
      </div>
    </section>
  );
};

export default HeroSection;
