import React, { useState, useEffect } from "react";

const InsuranceRentersSection = ({ onDataChange }) => {
  // Estado para controlar si el checkbox está marcado o no
  const [isChecked, setIsChecked] = useState(false);

  // Efecto para actualizar la visibilidad del contenido según el checkbox
  useEffect(() => {
    const element = document.querySelector(".insuranceRentersCheckboxActived");
    if (isChecked) {
      element.classList.add("showElement");
      element.classList.remove("Hidden");
    } else {
      element.classList.add("Hidden");
      element.classList.remove("showElement");
    }
  }, [isChecked]);

  const [part8Data, setPart8Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart8Data({
      ...part8Data,
      [name]: value,
    });
    onDataChange({
      ...part8Data,
      [name]: value,
    });
  };

  return (
    <section className="insuranceRenters__Section">
      <div className="checkboxContainer">
        <label className="checkbox-label" htmlFor="Renters__Checkbox">
          Renter's
          <input
            type="checkbox"
            id="Renters__Checkbox"
            name="Renters__Checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)} // Actualiza el estado al cambiar el checkbox
          />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="insuranceRentersCheckboxActived Hidden">
        <div className="uploadFileContainer">
          <svg
            fill="#ffffff"
            height="24px"
            width="24px"
            viewBox="0 0 330.591 330.591"
          >
            <path
              d="M52.575,320.395c-0.693,0-1.391-0.015-2.09-0.043c-12.979-0.54-25.361-6.071-34.865-15.576
              c-9.504-9.504-15.035-21.886-15.576-34.864c-0.549-13.213,4.115-25.456,13.133-34.475L221.581,27.033
              c11.523-11.523,27.197-17.483,44.096-16.78c16.676,0.693,32.594,7.81,44.822,20.037c12.228,12.229,19.346,28.147,20.037,44.823
              c0.703,16.911-5.256,32.571-16.781,44.096L156.711,276.255c-2.928,2.927-7.676,2.928-10.607,0
              c-2.928-2.93-2.928-7.678,0-10.608l157.045-157.047c8.523-8.522,12.928-20.194,12.4-32.865
              c-0.537-12.906-6.098-25.279-15.658-34.84c-9.559-9.56-21.932-15.119-34.838-15.656c-12.67-0.533-24.344,3.876-32.865,12.399
              L23.784,246.044c-12.596,12.594-11.498,34.184,2.443,48.125c6.836,6.837,15.672,10.813,24.881,11.195
              c8.975,0.349,17.229-2.734,23.244-8.752l169.441-169.439c7.422-7.422,6.691-20.229-1.629-28.549
              c-4.113-4.114-9.414-6.505-14.924-6.733c-5.289-0.212-10.115,1.595-13.625,5.106L95.536,215.08
              c-2.93,2.927-7.678,2.928-10.607,0c-2.93-2.93-2.93-7.678,0-10.607L203.008,86.39c6.512-6.512,15.322-9.9,24.855-9.486
              c9.281,0.385,18.127,4.332,24.906,11.114c14.17,14.167,14.9,36.49,1.631,49.762L84.959,307.22
              C76.418,315.76,64.985,320.395,52.575,320.395z"
            />
          </svg>
          <input type="file" placeholder="none" className="uploadFile" />
          <span>Upload File</span>
        </div>

        <h3 className="copyLatestLeaseAgreements marginTopTwo">
          Property / Renter’s Insurance & warranty:
        </h3>

        <div className="insuranceProperty__Inputs">
          <div className="dFColumn">
            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Provider"
                className="sectionTwelve__Label weightBold"
              >
                Provider:
              </label>
              <input
                type="text"
                name="Insurance_Renters_Provider"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Provider"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Policy"
                className="sectionTwelve__Label weightBold"
              >
                Policy:
              </label>
              <input
                type="text"
                name="Insurance_Renters_Policy"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Policy"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_InsuredName"
                className="sectionTwelve__Label weightBold"
              >
                Insured
                <br />
                Name:
              </label>
              <input
                type="text"
                name="Insurance_Renters_InsuredName"
                className="sectionTwelve__Input"
                id="Insurance_Renters_InsuredName"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Website"
                className="sectionTwelve__Label weightBold"
              >
                Website:
              </label>
              <input
                type="text"
                name="Insurance_Renters_Website"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Website"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Password"
                className="sectionTwelve__Label weightBold"
              >
                Password:
              </label>
              <input
                type="password"
                name="Insurance_Renters_Password"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Password"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="dFColumn">
            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_AgentName"
                className="sectionTwelve__Label weightBold"
              >
                Agent Name:
              </label>
              <input
                type="text"
                name="Insurance_Renters_AgentName"
                className="sectionTwelve__Input"
                id="Insurance_Renters_AgentName"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Account"
                className="sectionTwelve__Label weightBold"
              >
                Account:
              </label>
              <input
                type="text"
                name="Insurance_Renters_Account"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Account"
                onChange={handleChange}
              />
            </div>

            <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address flexEnd">
              <label
                htmlFor="Insurance_Renters_Username"
                className="sectionTwelve__Label weightBold"
              >
                Username:
              </label>
              <input
                type="text"
                name="Insurance_Renters_Username"
                className="sectionTwelve__Input"
                id="Insurance_Renters_Username"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsuranceRentersSection;
